<template>
  <div>
    <van-cell :title="PatientName">
      <!-- 使用 title 插槽来自定义标题 -->
    </van-cell>
    <van-cell>
      <template slot="title">
        <span style="color:#57c4b7; font-size:12px;"
          >*温馨提示：使用微信医保支付后需等待系统处理，成功或失败会在公众号窗口推送。</span
        >
      </template>
    </van-cell>
    <van-tabs color="#57c4b7" style="margin-top:5px;" v-model="activeName">
      <van-tab title="待支付" name="a">
        <van-list v-model="loading" :finished="finished">
          <van-cell title="待缴费订单">
            <template slot="default">
              总金额：
              <span style="color: #57c4b7">¥{{ FeeSum }}</span>
            </template>
          </van-cell>
          <van-cell-group v-for="item in lists" :key="item + 'payl'">
            <van-cell
              v-if="lists.length > 0"
              v-bind="item"
              @click="gotoPay(item.regNo)"
            >
              <!-- <van-cell v-bind="item"> -->
              <template slot="title">
                <span style="font-size: 16px">{{ item.deptName }}</span>
                <span style="color: #57c4b7">|</span>
                <span style="font-size: 12px">{{ item.drName }}</span>
                <span style="color: #57c4b7">|</span>
                <span style="color: red; font-size: 12px">未支付</span>
              </template>

              <template slot="label">
                <span style="font-size: 14px">就诊时间：</span>
                <span style="color: #57c4b7; font-size: 14px">{{
                  item.operTime
                }}</span>
              </template>
              <template slot="right-icon">
                <span style="color: #57c4b7; text-align: center">
                  <p>¥{{ item.totalFee }}</p>
                  <!-- <van-button
                  round
                  :disabled="disabled"
                  size="small"
                  style="
                    background: #57c4b7;
                    display: block;
                    color: white;
                    float: right;
                  "
                  text="立即支付"
                  @click="onToast"
                /> -->
                  <van-button
                    round
                    :disabled="disabled"
                    size="small"
                    style="
                  background: #57c4b7;
                  display: block;
                  color: white;
                  float: right;
                "
                    text="前往支付"
                    @click.stop="gotoPay(item.regNo)"
                  />
                </span>
              </template>
            </van-cell>

            <!-- sd -->
          </van-cell-group>
          <!-- <vant-cell-group> -->
          <!-- cs -->
          <!-- <van-cell @click=" gotoPay( 
                    
                  )"> -->
          <!-- <van-cell v-bind="item"> -->
          <!-- <template slot="title">
            <span style="font-size: 16px">cs</span>
            <span style="color: #57c4b7">|</span>
            <span style="font-size: 12px">cs</span>
            <span style="color: #57c4b7">|</span>
            <span style="color: red; font-size: 12px">未支付</span>
          </template>
          
          <template slot="label">
            <span style="font-size: 14px">就诊时间：</span>
            <span style="color: #57c4b7; font-size: 14px">
              22222
           </span>
          </template>
          <template slot="right-icon">
            <span style="color: #57c4b7; text-align: center">
              <p>¥2323</p> -->
          <!-- <van-button
                  round
                  :disabled="disabled"
                  size="small"
                  style="
                    background: #57c4b7;
                    display: block;
                    color: white;
                    float: right;
                  "
                  text="立即支付"
                  @click="onToast"
                /> -->
          <!-- <van-button
                round
                :disabled="disabled"
                size="small"
                style="
                  background: #57c4b7;
                  display: block;
                  color: white;
                  float: right;
                "
                text="前往支付"
                @click.stop="
                  gotoPay( 
                   
                  )
                "
              />
            </span>
          </template>
        </van-cell>
      </vant-cell-group> -->
        </van-list>
      </van-tab>
      <!-- <van-tab title="已支付" name="b">
        <van-cell-group @click="onclickOrder(item.orderId)" v-for="item in PaidList" :key="item + 'payl'">
        <van-cell v-bind="item">
       
          <template slot="title">
            <span style="font-size: 12px">{{ item.content }}</span>
          
            <div>
               <span style="color: #57c4b7; font-size: 12px">{{item.stateDescript}}</span>
            </div>
           
          </template>
          
          <template slot="label">
            <span style="font-size: 14px">支付时间：</span>
            <span style="color: #57c4b7; font-size: 14px">{{
              item.wxPaidTime
            }}</span>
          </template>
          <template slot="right-icon">
            <span style="color: #57c4b7; text-align: center">
              <p>¥{{ item.cost }}</p>
          
              
            </span>
          </template>
        </van-cell>
        
        
      </van-cell-group>
      </van-tab> -->
      <!-- <van-tab title="已退款" name="c">已退款</van-tab> -->
    </van-tabs>

    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import appconst from "../../lib/appconst";
import store from "../../store";
import ajax from "../../lib/ajax";
// const OperatorId = "0733";
var listss = [];
// let orderid = "";
// let sum = 0;
export default {
  data() {
    return {
      activeName: "a",
      deptListData: [
        { deptName: "内一科", deptId: "0304" },
        { deptName: "内二科", deptId: "0300" },
        { deptName: "内二科", deptId: "0303" },
        { deptName: "外一科", deptId: "0400" },
        { deptName: "外二科", deptId: "0400" },
        { deptName: "皮肤科", deptId: "1301" },
        { deptName: "口腔科", deptId: "1201" },
        { deptName: "儿科", deptId: "0700" },
        { deptName: "妇产科", deptId: "0500" },
      ],
      PaidList: [],
      PatientName: "",
      CardNumber: "",
      PayStatus: "",
      patientid: "",
      Idcard: "",
      FeeSum: 0,
      card: "",
      disabled: false,
      lists: [],
      payArg: {},
      loading: false,
      finished: false,
      wxPayInfo: {
        timestamp: "",
        nonceStr: "",
        package: "",
        signType: "",
        paySign: "",
      },
      wxconfig: {
        debug: false,
        appId: "",
        timestamp: "",
        nonceStr: "",
        signature: "",
        jsApiList: ["chooseWXPay", "hideOptionMenu"],
      },
      qrCodeText: "",
    };
  },
  created() {
    this.PatientName = localStorage.getItem("userName"); //store.getters["store/userName"];
    this.CardNumber = store.getters["store/cardNo"]; //声明就诊卡号
    this.patientid = store.getters["store/patientid"]; //声明病例号
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
    // this.getPaidList();
    this.getUnpaidList();
    // this.gotoPay();
  },
  methods: {
    isWeixin() {
      return /MicroMessenger/gi.test(navigator.userAgent);
    },
    initWxConfig() {
      // 初始化微信JS功能配置
      let me = this;
      return new Promise((resolve, reject) => {
        me.getWxjsSign(appconst.wechatAppId)
          .then((x) => {
            // alert(appconst.wechatAppId);
            var config = {
              appId: appconst.wechatAppId,
              timestamp: x["timestamp"],
              nonceStr: x["noncestr"],
              signature: x["sign"],
              ticket: x["ticket"],
            };
            me.wxconfig = Object.assign(me.wxconfig, config);
            wx.ready((x) => {
              if (x) {
                //
              }
              resolve(me.wxconfig);
            });
            wx.error((err) => {
              if (err) {
                //
              }
              reject(x);
            });
            wx.config(me.wxconfig);
            // alert(wx);
          })
          .catch((x) => {
            reject(x);
          });
      });
    },
    // 获取微信JS签名
    getWxjsSign() {
      let url = encodeURIComponent(location.href);
      var center = `${appconst.thirdApiCenterBaseUrl}Api/WxMpOauth/JsSign`;
      return new Promise((resolve, reject) => {
        ajax
          .get(center, {
            params: {
              appid: appconst.wechatAppId,
              url: url,
            },
          })
          .then((x) => {
            resolve(x.data.result);
            console.log("测试" + x.data.result);
          })
          .catch((x) => {
            reject(x);
            console.log(x);
          });
      });
    },
    getPaidList() {
      ajax
        .post(`/Api/WxYBPayServices/List`, {
          begin: "2022-12-15T09:01:16.071Z",
          end: "2023-01-28T09:01:16.071Z",
          state: 2,
          oid: 0,
          medoid: "",
          hisRegno: "",
          username: this.PatientName,
        })
        .then((res) => {
          console.log(res);
          this.PaidList = res.data.result;
        });
    },
    async getUnpaidList() {
      let me = this;
      if (me.isWeixin()) {
        me.initWxConfig();
        wx.hideOptionMenu(); // 禁用分享

        // TODO：加载订单信息用于显示
      } else {
        Toast.fail({ message: "请在微信中打开此页面", duration: 0 });
      }
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.lists.push(this.lists.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.lists.length == this.lists.length) {
          this.finished = true;
        }
      }, 500);

      // 微信获取病人缴费单据
      // let idcard = localStorage.getItem("idCard")//store.getters["store/idcard"];
      let outpatientId = localStorage.getItem("userId");
      await ajax
        .post("/Api/FeeService/GetFeeList", {
          userID: outpatientId,
          payStatus: 0,
          operatorId: "100002",
          payWay: 3,
          machineName: "wechat",
        })
        .then((rep) => {
          if (rep.data.success) {
            listss = rep.data.result.clinicFees;
            this.payArg = rep.data.result.payArgs;
            // console.log("listss.length", listss.length);
            if (listss.length != 0) {
              this.lists = listss;
              for (let i = 0; i < listss.length; i++) {
                const el = listss[i].totalFee;
                this.FeeSum += el;
              }
            } else {
              Toast("暂无可缴费订单，请稍后再试");
            }
          } else {
            Toast(rep.data.result.errorMsg);
          }
        })
        .catch((err) => {
          console.log(err.message);
          Toast("获取缴费列表异常：" + err.message);
          // Dialog.alert({
          //   title: "提示",
          //   message:
          //     "可能由无可缴费的订单引起，请开完处方后再进行查询"
          // });
        });
    },
    // 查看处方详细信息
    onclickOrder(orderId) {
      // store.commit("store/setRegNo", regNo);
      // store.commit("store/setSeqNo", seqNos);
      this.$router
        .replace({
          //path:'/Ordei/:OrderId',
          path: `/MordeI?orderId${orderId}`,
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 前往医保支付
    gotoPay(regNo) {
      // 正式模式下(old)
      // http://wx.fgxrmyy.cn/Mpay?regNo=${regNo}`}
      // ajax.get(`/Api/WxYBPayServices/GetWechatAuthorizationUrl?retUrl=http://wx.fgxrmyy.cn/Mpay?regNo=${regNo}`)
      // .then((res)=>{
      //   if(res.data.result){
      //     window.location.href=res.data.result;
      //   }else{
      //      Toast("获取医保授权连接失败");
      //   }
      //   console.log(res)
      // })
      // .catch(()=>{
      //  Toast("获取医保授权连接失败");
      // })
      // 正式模式下
      this.$router.replace({ path: `/Mpay?regNo=${regNo}` }).catch((err) => {
        console.log(err);
      });
    },

    onToast() {
      Toast("正在测试中");
    },
  },
};
</script>

<style></style>
